import React from 'react';

import './App.css';
import Home from "./Home";

function App() {
  return (
    <div className="section"> 
      <Home />
    </div>
  );  
}

export default App;
